import { Box } from 'components/Box';
import { CmsApiAnchorBlockData } from 'modules/cms/api/types/blocks/CmsApiAnchorBlock';

type Props = {
  data: CmsApiAnchorBlockData;
};

export function CmsAnchorBlock({ data: { anchor } }: Props) {
  return (
    <Box
      component="a"
      data-qa-id={`anchor-block-${anchor}`}
      id={anchor}
      className="anchor-block"
    />
  );
}
