import { CmsApiPage } from 'modules/cms/api/types/CmsApiPage';
import { isDefined } from 'utils/functional';

type Props = {
  content: CmsApiPage;
};

export function getContentWithBanner({ content }: Props) {
  return isDefined(content.heroSearch);
}
