import styled from 'styled-components';

import { FilestackImage } from 'components/FilestackImage/FilestackImage';
import { BodySmall } from 'components/Text/BodySmall';
import { CmsApiPage } from 'modules/cms/api/types/CmsApiPage';
import { getIsContentFullWidthLayout } from 'modules/cms/helpers';
import { colors } from 'theme/theme';

const HeaderImage = styled.div`
  img {
    height: 100%;
    width: 100%;
  }
`;

type Props = {
  content: CmsApiPage;
};

export function CmsHeaderImage({ content }: Props) {
  const fullWidthLayout = getIsContentFullWidthLayout({ content });

  return (
    <HeaderImage>
      <FilestackImage
        data-qa-id="cms-page-header-image"
        data={content.headerImage}
        width={fullWidthLayout ? 1152 : 952}
        alt={
          // @ts-expect-error TS(2533): Object is possibly 'null' or 'undefined'.
          content.headerImage.altText
            ? // @ts-expect-error TS(2533): Object is possibly 'null' or 'undefined'.
              content.headerImage.altText
            : content.title
        }
      />

      {
        // @ts-expect-error TS(2533): Object is possibly 'null' or 'undefined'.
        content.headerImage.caption && (
          <BodySmall color={colors.lightContentGrey} mt="8px">
            {
              // @ts-expect-error TS(2533): Object is possibly 'null' or 'undefined'.
              content.headerImage.caption
            }
          </BodySmall>
        )
      }
    </HeaderImage>
  );
}
