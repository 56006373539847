import { getFilestackScaledImageSize } from './getFilestackImageSize';

type Args = {
  originalSize: { width: number; height: number };
  width: number | undefined;
  height: number | undefined;
  aspectRatio: { width: number; height: number };
};

export function getFilestackImageSizes({
  originalSize,
  width,
  height,
  aspectRatio,
}: Args): {
  size1x: { width: number; height: number };
  size2x?: { width: number; height: number };
} {
  const size1x = getFilestackScaledImageSize({
    originalSize,
    partialSize: { width, height },
    aspectRatio,
  });

  if (!width && !height) return { size1x };

  const size2x = getFilestackScaledImageSize({
    originalSize,
    partialSize: {
      width: width ? width * 2 : undefined,
      height: height ? height * 2 : undefined,
    },
    aspectRatio,
  });

  if (size2x.width === size1x.width) return { size1x };

  return { size1x, size2x };
}
