import styled from 'styled-components';

import { Box } from 'components/Box';
import { CmsApiBlockType } from 'modules/cms/api/types/CmsApiBlock';
import { colors, cssBreakpoints } from 'theme/theme';

export const CmsBlockContainerBox = styled(Box)<{
  $showHeadingDivider: boolean;
  $hideFirstHeadingDivider: boolean;
  $fullWidthLayout: boolean;
  $hasBackground: boolean;
  $type: CmsApiBlockType;
}>`
  ${(props) =>
    props.$showHeadingDivider &&
    `
    h2${props.$hideFirstHeadingDivider ? ':not(:first-child)' : ''} {
      width: 100%;
      &::before {
        content: '';
        display: block;
        margin: 0 auto;
        margin-bottom: ${props.$fullWidthLayout ? 64 : 40}px;
        width: 100%;
        max-width: 1200px;
        height: 1px;
        background: ${colors.selectionGrey};
        @media all and (min-width: ${cssBreakpoints.smUp}) {
          margin-bottom: ${props.$fullWidthLayout ? 70 : 60}px;
        }
      }
    }
  `};

  ${(props) =>
    props.$hasBackground &&
    props.$type !== 'SPLIT_CONTENT' &&
    `
    &::before {
      content: '';
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: ${colors.backgroundGrey};
      > * {
        position: relative;
      }
  }`}

  ${(props) =>
    props.$hasBackground &&
    props.$type === 'SPLIT_CONTENT' &&
    `

    @media all and (min-width: ${cssBreakpoints.mdUp}) {

    &::before {
      content: '';
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: ${colors.backgroundGrey};
      > * {
        position: relative;
      }
  }}`}
`;
